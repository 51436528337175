import api from 'api';
import axiosClient from 'utils/axiosClient';
import {formData} from 'utils/helpers/formData';

const commands = async (data: any) => {
	const response = await axiosClient.get(api.ARCHIVE.COMMANDS.COMMANDS + '?isArchived=1', {
		params: data
	});
	return response.data;
};

const commandsById = async (id: number) => {
	const response = await axiosClient.get(api.ARCHIVE.COMMANDS.COMMANDS + id);
	return response.data;
};

const deleteCommands = async (ids: number[]) => {
	const response = await axiosClient.delete(api.ARCHIVE.COMMANDS.COMMANDS + ids);
	return response.data;
};

const uploadCommands = async (data: any) => {
	const response = await axiosClient.post(api.ARCHIVE.COMMANDS.UPLOAD_COMMANDS, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});

	return response.data;
};

const service = {
	commands,
	commandsById,
	deleteCommands,
	uploadCommands
};

export default service;
