import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getCertificates = async (data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.CERTIFICATE, {params: data});
	return response.data;
};

const createCertificate = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.CERTIFICATE, formData(data));
	return response.data;
};

const updateCertificate = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.CERTIFICATE_BY_ID + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deleteCertificates = async (ids: number[]) => {
	const response = await axiosClient.delete(api.STUDENTS.CERTIFICATE_BY_ID + ids);
	return response.data;
};

const getCertificateStudents = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.CERTIFICATE_BY_ID + id + '/students', {params: data});
	return response.data;
};

const createCertificateStudents = async (data: any, id: number) => {
	const response = await axiosClient.post(api.STUDENTS.CERTIFICATE_BY_ID + id + '/students', data);
	return response.data;
};

const deleteCertificateStudents = async (id: number, ids: number[]) => {
	const response = await axiosClient.delete(api.STUDENTS.CERTIFICATE_BY_ID + id + '/students/' + ids);
	return response.data;
};

const service = {
	getCertificates,
	createCertificate,
	updateCertificate,
	deleteCertificates,
	getCertificateStudents,
	createCertificateStudents,
	deleteCertificateStudents
};

export default service;
