enum API {
	GET_LECTURERS = '/api/lecturers/main',
	LECTURERS_DEPARTMENTS = '/api/lecturers/departments',
	LECTURERS_PULPITS = '/api/lecturers/pulpits',
	GET_LECTURERS_BY_ID = '/api/lecturers/main/',
	ADD_LECTURER_PERSONAL = '/api/lecturers/main/personal',
	UPDATE_LECTURER_PERSONAL = '/api/lecturers/main/personal/',
	LECTURER_PROFESSION = '/api/lecturers/main/profession/',
	LECTURER_WORK = '/api/lecturers/main/work/',
	LECTURER_WORK_EXPERIENCE = '/api/lecturers/main/workExperience',
	LECTURER_SCIENTIFIC_EXPERIENCE = '/api/lecturers/main/scientificResearchExperience',
	LECTURER_DOCUMENTS = '/api/lecturers/main/documents/',
	LECTURER_CONTRACT_INFO = '/api/lecturers/main/contract/',
	LECTURER_VACATION = '/api/lecturers/main/vacation/',
	LECTURER_VACATION_INFORMATION = '/api/lecturers/main/vacationInformation/',
	LECTURER_TEACHING_SUBJECTS = '/api/lecturers/main/teachingSubjects/',
	LECTURERS_SALARIES = '/api/lecturers/salaries',
	LECTURERS_ATTENDANCE = '/api/lecturers/lessons',
	LECTURERS_FIXED_SALARY = 'api/lecturers/fixedSalaries',
	RECTORATES = '/api/lecturers/rectorates',
	UPLOAD_LECTURER = '/api/lecturers/main/uploadLecturerInfo',
	SEND_PASSWORD_RESET_MAILS = '/api/lecturers/main/changeUserPasswordBulk'
}

export default API;
