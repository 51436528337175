import axiosClient from 'utils/axiosClient';
import api from 'api';

const getDistribution = async (data: any) => {
	const response = await axiosClient.get(api.ADMISSION.DISTRIBUTION.DISTRIBUTION, {params: data});
	return response.data;
};

const distribute = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.DISTRIBUTION.DISTRIBUTION, data);
	return response.data;
};

const deleteDistribution = async (id: any) => {
	const response = await axiosClient.delete(api.ADMISSION.DISTRIBUTION.DISTRIBUTION_BY_ID + id);
	return response.data;
};

const service = {
	getDistribution,
	distribute,
	deleteDistribution
};

export default service;
