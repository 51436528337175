import axiosClient from 'utils/axiosClient';
import api from 'api';

const getConsultations = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.CONSULTATION.CONSULTATION, {params: data});
	return response.data;
};

const createConsultation = async (data: any) => {
	const response = await axiosClient.post(api.EXAMS.CONSULTATION.CONSULTATION, data);
	return response.data;
};

const updateConsultation = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.EXAMS.CONSULTATION.CONSULTATION_BY_ID + id, data);
	return response.data;
};

const deleteConsultation = async (id: number[]) => {
	const response = await axiosClient.delete(api.EXAMS.CONSULTATION.CONSULTATION_BY_ID + id);
	return response.data;
};

const distributeConsultation = async (data: any) => {
	const response = await axiosClient.post(api.EXAMS.CONSULTATION.DISTRIBUTE_CONSULTATION, data);
	return response.data;
};

const service = {
	getConsultations,
	createConsultation,
	updateConsultation,
	deleteConsultation,
	distributeConsultation
};

export default service;
