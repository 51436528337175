import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getDiscounts = async (data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.DISCOUNTS, {params: data});
	return response.data;
};

const createDiscount = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.DISCOUNTS, formData(data));
	return response.data;
};

const updateDiscount = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.DISCOUNTS + '/' + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deleteDiscounts = async (ids: number[]) => {
	const response = await axiosClient.delete(api.STUDENTS.DISCOUNTS + '/' + ids);
	return response.data;
};

const service = {
	getDiscounts,
	createDiscount,
	updateDiscount,
	deleteDiscounts
};

export default service;
