import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExams = async (data: any) => {
	const response = await axiosClient.get(api.ADMISSION.EXAMS.EXAMS, {params: data});
	return response.data;
};

const getExamById = async (id: number) => {
	const response = await axiosClient.get(api.ADMISSION.EXAMS.EXAMS_BY_ID + id);
	return response.data;
};

const createExam = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.EXAMS.EXAMS, data, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return response.data;
};

const updateExam = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ADMISSION.EXAMS.EXAMS_BY_ID + id, data);
	return response.data;
};

const deleteExam = async (id: number) => {
	const response = await axiosClient.delete(api.ADMISSION.EXAMS.EXAMS_BY_ID + id);
	return response.data;
};

const service = {
	getExams,
	getExamById,
	createExam,
	updateExam,
	deleteExam
};

export default service;
