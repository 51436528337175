import axiosClient from 'utils/axiosClient';
import api from 'api';

const getFlows = async (params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.FLOWS.FLOWS, {params});
	return response.data;
};

const getFlowById = async (id: number) => {
	const response = await axiosClient.get(api.CURRICULUM.FLOWS.FLOWS_BY_ID + id);
	return response.data;
};

const createFlow = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.FLOWS.FLOWS, data);
	return response.data;
};

const updateFlow = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.CURRICULUM.FLOWS.FLOWS_BY_ID + id, data);
	return response.data;
};

const deleteFlow = async (id: number) => {
	const response = await axiosClient.delete(api.CURRICULUM.FLOWS.FLOWS_BY_ID + id);
	return response.data;
};

const distributeFlow = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.FLOWS.FLOW_DISTRIBUTION, data);
	return response.data;
};

const deleteFlowStudents = async (groupId: number, ids: string) => {
	const response = await axiosClient.delete(api.CURRICULUM.FLOWS.FLOWS_BY_ID + groupId + '/' + ids);
	return response.data;
};

const addFlowStudents = async (flowID: number, groupIDs: number[]) => {
	const response = await axiosClient.post(
		api.CURRICULUM.FLOWS.FLOWS_BY_ID + flowID + '/' + groupIDs.join(',')
	);
	return response.data;
};

const service = {
	getFlows,
	getFlowById,
	createFlow,
	updateFlow,
	deleteFlow,
	distributeFlow,
	deleteFlowStudents,
	addFlowStudents
};

export default service;
