import React, {createContext, useContext, useState, useEffect, ReactNode} from 'react';
import {Socket} from 'socket.io-client';
import {initializeSocket} from 'utils/helpers/socket';

interface SocketContextType {
	socket: Socket | null;
	connectSocket: () => void;
}
interface SocketProviderProps {
	children: ReactNode;
}
const SocketContext = createContext<SocketContextType | undefined>(undefined);

export const useSocket = (): SocketContextType => {
	const context = useContext(SocketContext);
	if (!context) {
		throw new Error('useSocket must be used within a SocketProvider');
	}
	return context;
};

export const SocketProvider: React.FC<SocketProviderProps> = ({children}) => {
	const [socket, setSocket] = useState<Socket | null>(null);

	const connectSocket = (token = localStorage.getItem('token')) => {
		if (token) {
			const socketInstance = initializeSocket(token);
			setSocket(socketInstance);

			return socketInstance;
		}
	};

	useEffect(() => {
		connectSocket();
		return () => {
			if (socket) socket.disconnect();
		};
	}, []);

	return <SocketContext.Provider value={{socket, connectSocket}}>{children}</SocketContext.Provider>;
};
