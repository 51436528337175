import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExams = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.EXAMS.EXAMS, {params: data});
	return response.data;
};

const getExamById = async (id: number) => {
	const response = await axiosClient.get(api.EXAMS.EXAMS.EXAMS_BY_ID + id);
	return response.data;
};

const createExam = async (data: any) => {
	const response = await axiosClient.post(api.EXAMS.EXAMS.EXAMS, data);
	return response.data;
};

const updateExam = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.EXAMS.EXAMS.EXAMS_BY_ID + id, data);
	return response.data;
};

const deleteExam = async (ids: number[]) => {
	const response = await axiosClient.delete(api.EXAMS.EXAMS.EXAMS_BY_ID + ids);
	return response.data;
};

/*
 * Controllers
 */
const getExamControllers = async (data: any, id: number) => {
	const response = await axiosClient.get(api.EXAMS.EXAMS.EXAMS_BY_ID + id + '/controllers', {params: data});
	return response.data;
};

const createExamController = async (data: any, id: number) => {
	const response = await axiosClient.post(api.EXAMS.EXAMS.EXAMS_BY_ID + id + '/controllers', data);
	return response.data;
};

const updateExamController = async (data: any, id: number, examId: number) => {
	const response = await axiosClient.patch(
		api.EXAMS.EXAMS.EXAMS_BY_ID + examId + '/controllers/' + id,
		data
	);
	return response.data;
};

const deleteExamController = async (ids: number[], examId: number) => {
	const response = await axiosClient.delete(api.EXAMS.EXAMS.EXAMS_BY_ID + examId + '/controllers/' + ids);
	return response.data;
};

const service = {
	getExams,
	getExamById,
	createExam,
	updateExam,
	deleteExam,
	getExamControllers,
	createExamController,
	updateExamController,
	deleteExamController
};

export default service;
