import {createContext, useReducer, Dispatch, ReactNode} from 'react';

const initialState: NotificationsStateProps = {
	studentsWithoutFlowCount: 0,
	studentsWithoutGroupCount: 0,
	answeredAssignmentsWithoutMark: [],
	changedAssignmentsWithoutMark: [],
	unconfirmedExamMarksCount: 0,
	groupsWithoutFlowCount: 0,
	groupsWithoutFlowIDs: []
};

export interface NotificationsStateProps {
	studentsWithoutFlowCount: number;
	studentsWithoutGroupCount: number;
	changedAssignmentsWithoutMark: number[];
	answeredAssignmentsWithoutMark: number[];
	unconfirmedExamMarksCount: number;
	groupsWithoutFlowCount: number;
	groupsWithoutFlowIDs: number[];
}

type Action = {type: 'GET_NOT_ATTACHED_STUDENTS'; payload: NotificationsStateProps};

interface InitContextProps {
	state: NotificationsStateProps;
	dispatch: Dispatch<Action>;
}

export const NotificationsContext = createContext({} as InitContextProps);

const reducer = (state: NotificationsStateProps, action: Action) => {
	switch (action.type) {
		case 'GET_NOT_ATTACHED_STUDENTS':
			return {
				...state,
				studentsWithoutFlowCount: action.payload.studentsWithoutFlowCount,
				changedAssignmentsWithoutMark: action.payload.changedAssignmentsWithoutMark,
				answeredAssignmentsWithoutMark: action.payload.answeredAssignmentsWithoutMark,
				studentsWithoutGroupCount: action.payload.studentsWithoutGroupCount,
				unconfirmedExamMarksCount: action.payload.unconfirmedExamMarksCount,
				groupsWithoutFlowCount: action.payload.groupsWithoutFlowCount,
				groupsWithoutFlowIDs: action.payload.groupsWithoutFlowIDs
			};
		default:
			return state;
	}
};

const NotificationsProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};

	return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export default NotificationsProvider;
