import {useEffect, createContext, useReducer, Dispatch, ReactNode} from 'react';
import funcs from 'utils/helpers/funcs';

const initialState = {
	isAuthenticated: undefined,
	token: null,
	id: null,
	privilegeId: null,
	name: null,
	lastName: null,
	email: null,
	userTypeID: null
};

export interface AuthStateProps {
	isAuthenticated: boolean | undefined;
	token: null | string;
	id: null | string;
	privilegeId: null | string;
	userTypeID: null | string;
	name: null | string;
	lastName: null | string;
	email: null | string;
}

type Action =
	| {
			type: 'LOGIN';
			payload: {
				token: string;
				name: string;
				lastName: string;
				email: string;
				id: string;
				privilegeId: string;
				userTypeID: string;
			};
	  }
	| {type: 'LOGOUT'};

interface InitContextProps {
	state: AuthStateProps;
	dispatch: Dispatch<Action>;
}

export const AuthContext = createContext({} as InitContextProps);

const reducer = (state: AuthStateProps, action: Action) => {
	switch (action.type) {
		case 'LOGIN':
			localStorage.setItem('token', action.payload.token);
			localStorage.setItem('name', action.payload.name);
			localStorage.setItem('lastName', action.payload.lastName);
			localStorage.setItem('email', action.payload.email);
			localStorage.setItem('id', action.payload.id);
			localStorage.setItem('privilegeId', action.payload.privilegeId);
			localStorage.setItem('userTypeID', action.payload.userTypeID);

			return {
				...state,
				isAuthenticated: true,
				token: action.payload.token,
				name: action.payload.name,
				lastName: action.payload.lastName,
				email: action.payload.email,
				id: action.payload.id,
				privilegeId: action.payload.privilegeId,
				userTypeID: action.payload.userTypeID
			};
		case 'LOGOUT':
			funcs.deleteStorageDefaultKeys();
			return {
				...initialState,
				isAuthenticated: false
			};
		default:
			return state;
	}
};

const AuthProvider = ({children}: {children: ReactNode}) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = {state, dispatch};

	useEffect(() => {
		const token = localStorage.getItem('token');
		const name = localStorage.getItem('name');
		const lastName = localStorage.getItem('lastName');
		const email = localStorage.getItem('email');
		const id = localStorage.getItem('id');
		const privilegeId = localStorage.getItem('privilegeId');
		const userTypeID = localStorage.getItem('userTypeID');

		if (token && name && lastName && email && id && privilegeId && userTypeID) {
			dispatch({
				type: 'LOGIN',
				payload: {
					token,
					name,
					lastName,
					email,
					id,
					privilegeId,
					userTypeID
				}
			});
		} else {
			dispatch({
				type: 'LOGOUT'
			});
		}
	}, []);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
