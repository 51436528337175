import axiosClient from 'utils/axiosClient';
import api from 'api';

const getAbsence = async (params?: any) => {
	const response = await axiosClient.get(api.ATTENDANCE.ABSENCE.ABSENCE, {params});
	return response.data;
};

const service = {
	getAbsence
};

export default service;
