import axiosClient from 'utils/axiosClient';
import api from 'api';

const getReportClassrooms = async (data: any) => {
	const response = await axiosClient.get(api.REPORTS.REPORTS_CLASSROOMS, {params: data});
	return response.data;
};

const service = {
	getReportClassrooms
};

export default service;
