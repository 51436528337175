import axiosClient from 'utils/axiosClient';
import api from 'api';

const getSummary = async (data: any, examsByFaculty: any) => {
	const response = await axiosClient.get(api.ADMISSION.SUMMARY.SUMMARY, {params: data});
	const newData = response.data.data.data.map((el: any) => {
		let totalMaxGrade = 0;
		const exams = JSON.parse(el.exams);
		const data = {...el};
		exams.forEach((e: any) => {
			data[e.examID] = e.mark;
			totalMaxGrade += examsByFaculty.find((el: any) => el.id === e.examID).maxGrade;
		});
		data.totalMaxGrade = totalMaxGrade;
		return data;
	});

	return {data: newData, pagination: response.data.data?.pagination};
};

const createSummary = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.SUMMARY.SUMMARY, data, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return response.data;
};

const updateSummary = async (data: any, id: number[]) => {
	const response = await axiosClient.patch(api.ADMISSION.SUMMARY.SUMMARY + '/' + id, data);
	return response.data;
};

const sendSummaryEmail = async (data: any) => {
	const response = await axiosClient.post(`${api.ADMISSION.SUMMARY.SEND_SUMMARY_ENAIL}`, data);
	return response.data;
};

const service = {
	getSummary,
	createSummary,
	updateSummary,
	sendSummaryEmail
};

export default service;
