import axiosClient from 'utils/axiosClient';
import api from 'api';

const getCompletedClasses = async (params?: any) => {
	const response = await axiosClient.get(api.ATTENDANCE.COMPLETED_CLASSES.MAIN, {params});
	return response.data;
};

const service = {
	getCompletedClasses
};

export default service;
