import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExamsSummary = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.SUMMARY.SUMMARY, {params: data});
	return response.data;
};

const getSummarySubjects = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.SUMMARY.SUMMARY_SUBJECTS, {params: data});
	return response.data;
};

const createExamSummary = async (data: any) => {
	const response = await axiosClient.post(api.EXAMS.SUMMARY.SUMMARY, data);
	return response.data;
};

const updateExamSummary = async (data: any, ids: number[]) => {
	const response = await axiosClient.patch(api.EXAMS.SUMMARY.SUMMARY_BY_ID + ids, data);
	return response.data;
};

const service = {
	getExamsSummary,
	getSummarySubjects,
	createExamSummary,
	updateExamSummary
};

export default service;
