import axiosClient from 'utils/axiosClient';
import {formData} from 'utils/helpers/formData';
import api from 'api';

const getCommands = async (data: any) => {
	const response = await axiosClient.get(api.COMMANDS.COMMANDS, {params: data});
	return response.data;
};
const getDocument = async (path: string) => {
	const response = await axiosClient.get(path);
	return response.data;
};

const getCommandsById = async (id: number) => {
	const response = await axiosClient.get(api.COMMANDS.COMMANDS_BY_ID + id);
	return response.data;
};

const updateCommandeUsers = async (data: any, id: number) => {
	const response = await axiosClient.post(api.COMMANDS.COMMANDS_BY_ID + id, data);
	return response.data;
};

const deleteCommandeUsers = async (commandId: number, ids: string) => {
	const response = await axiosClient.delete(api.COMMANDS.COMMANDS_BY_ID + commandId + '/' + ids);
	return response.data;
};

const createCommande = async (data: any) => {
	const response = await axiosClient.post(api.COMMANDS.COMMANDS, formData(data));
	return response.data;
};

const updateCommande = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.COMMANDS.COMMANDS_BY_ID + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deleteCommande = async (id: number) => {
	const response = await axiosClient.delete(api.COMMANDS.COMMANDS_BY_ID + id);
	return response.data;
};

const service = {
	getCommands,
	getDocument,
	getCommandsById,
	updateCommandeUsers,
	deleteCommandeUsers,
	createCommande,
	updateCommande,
	deleteCommande
};

export default service;
