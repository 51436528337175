import axiosClient from 'utils/axiosClient';
import api from 'api';

const getUsers = async (data: any) => {
	const response = await axiosClient.get(api.USERS.USERS, {params: data});
	return response.data;
};

const createUser = async (data: any) => {
	const response = await axiosClient.post(api.USERS.USERS, data);
	return response.data;
};

const updateUser = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.USERS.USERS + '/' + id, data);
	return response.data;
};

const updateUserPassword = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.USERS.USERS + '/' + id + '/changePassword', data);
	return response.data;
};

// const updateUser = async (data: any, id: number) => {
// 	const response = await axiosClient.patch(api.USERS.USERS + '/' + id, data);
// 	return response.data;
// };

const getProfile = async () => {
	const response = await axiosClient.get(api.USERS.PROFILE);
	return response.data;
};

const service = {
	getUsers,
	createUser,
	updateUser,
	updateUserPassword,
	getProfile
};

export default service;
