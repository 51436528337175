import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getGroups = async (data?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.GROUPS.GROUPS, {params: data});
	return response.data;
};

const getGroupById = async (id: number) => {
	const response = await axiosClient.get(api.CURRICULUM.GROUPS.GROUPS_BY_ID + id);
	return response.data;
};

const getGroupCurriculumById = async (id: number) => {
	const response = await axiosClient.get(api.CURRICULUM.GROUPS.GROUPS_BY_ID + 'main/curriculum/' + id, {
		params: {showModule: true}
	});
	return response.data;
};

const getStudentsCountByFaculty = async (data: any) => {
	const response = await axiosClient.get(api.CURRICULUM.GROUPS.GET_STUDENTS_COUNT_BY_FACULTY, {
		params: data
	});
	return response.data;
};

const getGroupNotificationsList = async (groupID: number) => {
	const response = await axiosClient.get(
		api.CURRICULUM.GROUPS.GROUPS_BY_ID + groupID + '/students/notification'
	);
	return response.data;
};

const sendNotificationsToGroupsStudents = async (groupID: number, data: any) => {
	const response = await axiosClient.post(
		api.CURRICULUM.GROUPS.GROUPS_BY_ID + groupID + '/students/notification',
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const moveGroupMembersToOtherGroup = async (groupID: number, studentIDS: number[], data: any) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.GROUPS.GROUPS_BY_ID + groupID + '/' + studentIDS.join(),
		data
	);
	return response.data;
};

const createGroup = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.GROUPS.GROUPS, data);
	return response.data;
};

const updateGroup = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.CURRICULUM.GROUPS.GROUPS_BY_ID + id, data);
	return response.data;
};

const deleteGroup = async (id: number) => {
	const response = await axiosClient.delete(api.CURRICULUM.GROUPS.GROUPS_BY_ID + id);
	return response.data;
};

const distributeGroup = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.GROUPS.GROUP_DISTRIBUTION, data);
	return response.data;
};

const deleteGroupStudents = async (groupId: number, ids: string) => {
	const response = await axiosClient.delete(api.CURRICULUM.GROUPS.GROUPS_BY_ID + groupId + '/' + ids);
	return response.data;
};

const addGroupStudents = async (groupId: number, ids: number[], data?: {semesterID: number}) => {
	const response = await axiosClient.post(api.CURRICULUM.GROUPS.GROUPS_BY_ID + groupId + '/' + ids, data);
	return response.data;
};

const service = {
	getGroups,
	getGroupById,
	createGroup,
	updateGroup,
	deleteGroup,
	getGroupNotificationsList,
	sendNotificationsToGroupsStudents,
	moveGroupMembersToOtherGroup,
	getStudentsCountByFaculty,
	distributeGroup,
	deleteGroupStudents,
	addGroupStudents,
	getGroupCurriculumById
};

export default service;
