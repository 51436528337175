import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const createMark = async (data: any) => {
	const response = await axiosClient.post(api.ARCHIVE.MARKS.MARKS, data);
	return response.data;
};

const marks = async (data: any) => {
	const response = await axiosClient.get(api.ARCHIVE.MARKS.MARKS, {params: data});
	return response.data;
};

const marksById = async (id: number) => {
	const response = await axiosClient.get(api.ARCHIVE.MARKS.MARKS_BY_ID + id);
	return response.data;
};

const updateMarks = async (id: number, data: any) => {
	const response = await axiosClient.patch(api.ARCHIVE.MARKS.MARKS_BY_ID + id, data);
	return response.data;
};

const deleteMarks = async (ids: number[]) => {
	const response = await axiosClient.delete(api.ARCHIVE.MARKS.MARKS_BY_ID + ids);
	return response.data;
};

const uploadStudentsMarks = async (data: any) => {
	const response = await axiosClient.post(api.ARCHIVE.MARKS.UPLOAD_MARKS, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const service = {
	createMark,
	marks,
	marksById,
	updateMarks,
	deleteMarks,
	uploadStudentsMarks
};

export default service;
