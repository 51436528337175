enum APPEALS {
	APPEALS = '/api/admission/appeals',
	APPEALS_BY_ID = '/api/admission/appeals/',
	DISTRIBUTION = '/api/admission/appealsDistribution'
}

enum CANDIDATES {
	CANDIDATE = '/api/admission/candidates',
	CANDIDATE_BY_ID = '/api/admission/candidates/',
	CHECK_CANDIDATE_EDIT = '/api/admission/candidates/check/'
}

enum DISTRIBUTION {
	DISTRIBUTION = '/api/admission/distribution',
	DISTRIBUTION_BY_ID = '/api/admission/distribution/'
}

enum EXAMS {
	EXAMS = '/api/admission/exams',
	EXAMS_BY_ID = '/api/admission/exams/'
}

enum FACULTIES {
	FACULTIES = '/api/admission/faculties',
	FACULTIES_BY_ID = '/api/admission/faculties/'
}

enum MARKS {
	MARKS = '/api/admission/marks',
	MARKS_BY_ID = '/api/admission/marks/',
	UPLOAD_MARKS = '/api/exams/marks/'
}

enum PAYMENTS {
	PAYMENTS = '/api/admission/payments',
	PAYMENTS_BY_ID = '/api/admission/payments/'
}

enum SUMMARY {
	SUMMARY = '/api/admission/summary',
	SEND_SUMMARY_ENAIL = 'api/admission/summary/sendEmails'
}

export default {
	APPEALS,
	CANDIDATES,
	DISTRIBUTION,
	EXAMS,
	FACULTIES,
	MARKS,
	PAYMENTS,
	SUMMARY
};
