import axiosClient from 'utils/axiosClient';
import api from 'api';

const getAttendanceById = async (id: number) => {
	const response = await axiosClient.get(api.STUDENTS.ATTENDANCE_BY_ID + id);
	return response.data;
};

const getAttendanceByStudentId = async (id: number, params: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/lessonAttendanceStatistics', {
		params
	});
	return response.data;
};

const getAbsenceById = async (params: any) => {
	const response = await axiosClient.get(api.STUDENTS.ATTENDANCE, {
		params
	});
	return response.data;
};

const service = {
	getAttendanceById,
	getAttendanceByStudentId,
	getAbsenceById
};

export default service;
