import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExamDistribution = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.DISTRIBUTION.DISTRIBUTION, {params: data});
	return response.data;
};

const createExamDistribution = async (data: any, examID: number) => {
	const response = await axiosClient.post(api.EXAMS.DISTRIBUTION.DISTRIBUTION_BY_ID + examID, data);
	return response.data;
};

const deleteExamDistribution = async (ids: number[]) => {
	const response = await axiosClient.delete(api.EXAMS.DISTRIBUTION.DISTRIBUTION_BY_ID + ids);
	return response.data;
};

const service = {
	getExamDistribution,
	createExamDistribution,
	deleteExamDistribution
};

export default service;
