import axiosClient from 'utils/axiosClient';
import api from 'api';

const getPrivileges = async (data: any) => {
	const response = await axiosClient.get(api.PRIVILEGES.PRIVILEGES, {params: data});
	return response.data;
};

const createPrivilege = async (data: any) => {
	const response = await axiosClient.post(api.PRIVILEGES.PRIVILEGES, data);
	return response.data;
};

const service = {
	getPrivileges,
	createPrivilege
};

export default service;
