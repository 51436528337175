import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getPayments = async (data: any) => {
	const response = await axiosClient.get(api.ADMISSION.PAYMENTS.PAYMENTS, {params: data});
	return response.data;
};

// const getPaymentById = async (id: number) => {
// 	const response = await axiosClient.get(api.ADMISSION.PAYMENTS.PAYMENTS_BY_ID + id);
// 	return response.data;
// };

const createPayment = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.PAYMENTS.PAYMENTS, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const updatePayment = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ADMISSION.PAYMENTS.PAYMENTS_BY_ID + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deletePayment = async (id: number) => {
	const response = await axiosClient.delete(api.ADMISSION.PAYMENTS.PAYMENTS_BY_ID + id);
	return response.data;
};

const uploadTheLastBalance = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.PAYMENTS_UPLOAD_THE_LAST_BALANCE, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const uploadThePaymentAmount = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.PAYMENTS_UPLOAD_THE_PAYMENT_AMOUNT, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const service = {
	updatePayment,
	getPayments,
	uploadTheLastBalance,
	uploadThePaymentAmount,
	// getPaymentById,
	createPayment,
	deletePayment
};

export default service;
