import axiosClient from 'utils/axiosClient';
import api from 'api';

const getSchedule = async (data?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.SCHEDULE.SCHEDULE, {params: data});
	return response.data;
};
const getScheduleByGroupID = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.SCHEDULE.SCHEDULE_BY_GROUP_ID + id, {params: data});
	return response.data;
};

const getLessonTimes = async () => {
	const response = await axiosClient.get('/api/settings/dictionary/curriculumLessonTimes');
	return response.data;
};

const service = {
	getSchedule,
	getScheduleByGroupID,
	getLessonTimes
};

export default service;
