import axiosClient from 'utils/axiosClient';
import api from 'api';

const getPermissions = async (data: any) => {
	const response = await axiosClient.get(api.PERMISSIONS.PERMISSIONS, {params: data});
	return response.data;
};

const updatePermissions = async (data: any) => {
	const response = await axiosClient.patch(api.PERMISSIONS.PERMISSIONS, data);
	return response.data;
};

const getActionPermissions = async (id: number) => {
	const response = await axiosClient.get(api.PERMISSIONS.ACTION_PERMISSIONS + id);
	return response.data;
};

const updateActionPermissions = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.PERMISSIONS.ACTION_PERMISSIONS + id, data);
	return response.data;
};

const service = {
	getPermissions,
	updatePermissions,
	getActionPermissions,
	updateActionPermissions
};

export default service;
