import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getLecturers = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS, {params: data});
	return response.data;
};

const getLecturersProfessionalForm = async (lecturerID: string | number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + `${lecturerID}/profession`, {
		params: data
	});
	return response.data;
};

const getLecturersDepartments = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_DEPARTMENTS, {params: data});
	return response.data;
};

const getLecturersDepartmentByID = async (id: number | string, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_DEPARTMENTS + `/${id}`, data);
	return response.data;
};

const getLecturersPulpits = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_PULPITS, {params: data});
	return response.data;
};

const getLecturersPulpitsByID = async (id: string | number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_PULPITS + `/${id}`, data);
	return response.data;
};

const getLecturerByID = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + id, {params: data});
	return response.data;
};

const getLecturerTeachingSubjects = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/teachingSubjects`, {
		params: data
	});
	return response.data;
};

const getLecturerContract = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/contract`, {
		params: data
	});
	return response.data;
};

const getLecturerDocuments = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/documents`, {
		params: data
	});
	return response.data;
};

const getLecturerCommands = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/commands`, {
		params: data
	});
	return response.data;
};

const getLecturerWorkExperience = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/workExperience`, {
		params: data
	});
	return response.data;
};
const getLecturerScientificResearchExperience = async (id: number, data?: any) => {
	const response = await axiosClient.get(
		api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/scientificResearchExperience`,
		{
			params: data
		}
	);
	return response.data;
};

const getLecturerFixedSalaries = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_FIXED_SALARY, {params: data});
	return response.data;
};

const getLecturerScientificExperience = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURER_SCIENTIFIC_EXPERIENCE, {params: data});
	return response.data;
};

const getRectorates = async (params?: any) => {
	const response = await axiosClient.get(api.LECTURERS.RECTORATES, {params});
	return response.data;
};

const getRectorateByID = async (id: number) => {
	const response = await axiosClient.get(api.LECTURERS.RECTORATES + '/' + id);
	return response.data;
};

const addLecturerPersonal = async (data?: any) => {
	const response = await axiosClient.post(api.LECTURERS.ADD_LECTURER_PERSONAL, data);
	return response.data;
};

const addLecturerDepartments = async (data?: any) => {
	const response = await axiosClient.post(api.LECTURERS.LECTURERS_DEPARTMENTS, data);
	return response.data;
};

const addLecturerPulpits = async (data?: any) => {
	const response = await axiosClient.post(api.LECTURERS.LECTURERS_PULPITS, data);
	return response.data;
};

const addLecturerProfession = async (data: any, id: number) => {
	const response = await axiosClient.post(
		api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/profession`,
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const addLecturerVacation = async (data: any, id: number) => {
	const response = await axiosClient.post(api.LECTURERS.LECTURER_VACATION + id, data);
	return response.data;
};

const getLecturerVacation = async (id: number, params?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURER_VACATION + id, {params});
	return response.data;
};

const addLecturerFixedSalary = async (data: any) => {
	const response = await axiosClient.post(api.LECTURERS.LECTURERS_FIXED_SALARY, data);
	return response.data;
};

const addLecturerWorkExperience = async (data: any, lecutreID: number | string) => {
	const response = await axiosClient.post(
		api.LECTURERS.GET_LECTURERS_BY_ID + `${lecutreID}/workExperience`,
		data
	);
	return response.data;
};
const addLecturerScientificExperience = async (data: any, lectureID: number | string) => {
	const response = await axiosClient.post(
		api.LECTURERS.GET_LECTURERS_BY_ID + `${lectureID}/scientificResearchExperience`,
		data
	);
	return response.data;
};

const addLecturerDocuments = async (data: any, id: number) => {
	const response = await axiosClient.post(
		api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/documents`,
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const addLecturerContract = async (data: any, id: number) => {
	const response = await axiosClient.post(api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/contract`, data);
	return response.data;
};

const addRectorate = async (data: any) => {
	const response = await axiosClient.post(api.LECTURERS.RECTORATES, data);
	return response.data;
};

const updateLecturerPersonal = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.UPDATE_LECTURER_PERSONAL + id, data);
	return response.data;
};

const updateLecturerProfession = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_PROFESSION + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const updateLecturerWork = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_WORK + id, data);
	return response.data;
};

const updateLecturerDocuments = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_DOCUMENTS + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});

	return response.data;
};

const updateLecturerContract = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_CONTRACT_INFO + id, data);
	return response.data;
};

const updateLecturerVacation = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_VACATION + id, data);
	return response.data;
};

const updateLecturerDepartments = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURERS_DEPARTMENTS + `/${id}`, data);
	return response.data;
};

const updateLecturerPulpits = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURERS_PULPITS + `/${id}`, data);
	return response.data;
};

const updateLecturerVacationInformation = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_VACATION_INFORMATION + id, data);
	return response.data;
};

const updateLecturerFixedSalary = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURERS_FIXED_SALARY + '/' + id, data);
	return response.data;
};

const updateLecturerWorkExperience = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_WORK_EXPERIENCE + '/' + id, data);
	return response.data;
};

const updateLecturerScientificExperience = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_SCIENTIFIC_EXPERIENCE + '/' + id, data);
	return response.data;
};

const updateRectorate = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.RECTORATES + '/' + id, data);
	return response.data;
};

const deleteLecturer = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.GET_LECTURERS_BY_ID + id);
	return response.data;
};

const deleteLecturerProfession = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_PROFESSION + id);
	return response.data;
};

const deleteLecturerContract = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_CONTRACT_INFO + id);
	return response.data;
};

const deleteLecturerDepartments = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURERS_DEPARTMENTS + `/${id}`);
	return response.data;
};

const deleteLecturerPulpits = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURERS_PULPITS + `/${id}`);
	return response.data;
};

const deleteLecturerDocument = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_DOCUMENTS + id);
	return response.data;
};

const deleteLecturerVacation = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_VACATION + id);
	return response.data;
};

const deleteLecturerFixedSalaries = async (ids: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURERS_FIXED_SALARY + '/' + ids);
	return response.data;
};

const deleteLecturerWorkExperience = async (ids: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_WORK_EXPERIENCE + '/' + ids);
	return response.data;
};

const deleteLecturerScientificExperience = async (ids: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_SCIENTIFIC_EXPERIENCE + '/' + ids);
	return response.data;
};

const deleteRectorate = async (ids: number) => {
	const response = await axiosClient.delete(api.LECTURERS.RECTORATES + '/' + ids);
	return response.data;
};

const addTeachingSubject = async (data: any, id: number) => {
	const response = await axiosClient.post(
		api.LECTURERS.GET_LECTURERS_BY_ID + `${id}/teachingSubjects`,
		data
	);
	return response.data;
};

const updateTeachingSubject = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.LECTURERS.LECTURER_TEACHING_SUBJECTS + id, data);
	return response.data;
};

const deleteTeachingSubject = async (id: number) => {
	const response = await axiosClient.delete(api.LECTURERS.LECTURER_TEACHING_SUBJECTS + id);
	return response.data;
};

const getLecturerSalariesByID = async (id: number) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_SALARIES + '/' + id);
	return response.data;
};

const getLecturersSalaries = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_SALARIES, {params: data});
	return response.data;
};

const summarizeLecturersSalaries = async (data?: any) => {
	const response = await axiosClient.post(api.LECTURERS.LECTURERS_SALARIES, data);
	return response.data;
};

const getLecturersAttendance = async (data?: any) => {
	const response = await axiosClient.get(api.LECTURERS.LECTURERS_ATTENDANCE, {params: data});
	return response.data;
};
const uploadLecturers = async (data: any) => {
	const response = await axiosClient.post(api.LECTURERS.UPLOAD_LECTURER, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const sendPasswordResetMails = async (IDs: number[], filters: any) => {
	const response = await axiosClient.post(api.LECTURERS.SEND_PASSWORD_RESET_MAILS, {
		IDs,
		filters
	});
	return response.data;
};

const service = {
	getLecturers,
	getLecturersProfessionalForm,
	getLecturerTeachingSubjects,
	getLecturerContract,
	getLecturerDocuments,
	getLecturerCommands,
	getLecturerVacation,
	getLecturerScientificResearchExperience,
	getLecturersDepartments,
	getLecturersPulpits,
	getLecturersPulpitsByID,
	getLecturerByID,
	getLecturerWorkExperience,
	getLecturerScientificExperience,
	getRectorates,
	getRectorateByID,
	addLecturerPersonal,
	addLecturerWorkExperience,
	addLecturerScientificExperience,
	addLecturerDepartments,
	getLecturersDepartmentByID,
	addLecturerPulpits,
	addLecturerProfession,
	addLecturerDocuments,
	addLecturerContract,
	addLecturerVacation,
	addRectorate,
	updateLecturerPersonal,
	updateLecturerProfession,
	updateLecturerWork,
	updateLecturerDocuments,
	updateLecturerContract,
	updateLecturerVacation,
	updateLecturerDepartments,
	updateLecturerPulpits,
	updateRectorate,
	updateLecturerWorkExperience,
	updateLecturerScientificExperience,
	updateLecturerVacationInformation,
	deleteLecturer,
	deleteLecturerProfession,
	deleteLecturerContract,
	deleteLecturerDocument,
	deleteLecturerWorkExperience,
	deleteLecturerScientificExperience,
	deleteLecturerVacation,
	addTeachingSubject,
	updateTeachingSubject,
	deleteTeachingSubject,
	getLecturersSalaries,
	getLecturerSalariesByID,
	getLecturersAttendance,
	summarizeLecturersSalaries,
	getLecturerFixedSalaries,
	addLecturerFixedSalary,
	updateLecturerFixedSalary,
	deleteLecturerFixedSalaries,
	deleteLecturerDepartments,
	deleteLecturerPulpits,
	deleteRectorate,
	uploadLecturers,
	sendPasswordResetMails
};

export default service;
