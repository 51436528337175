enum EXAMS {
	EXAMS = '/api/exams/main',
	EXAMS_BY_ID = '/api/exams/main/'
}

enum MARKS {
	MARKS = '/api/exams/marks',
	MARKS_BY_ID = '/api/exams/marks/',
	MARKS_CONFIRMATION = '/api/exams/marks/confirmation/'
}

enum DISTRIBUTION {
	DISTRIBUTION = '/api/exams/distribution',
	DISTRIBUTION_BY_ID = '/api/exams/distribution/'
}

enum CONSULTATION {
	CONSULTATION = '/api/exams/consultation',
	CONSULTATION_BY_ID = '/api/exams/consultation/',
	DISTRIBUTE_CONSULTATION = '/api/exams/consultation/distribute'
}

enum SUMMARY {
	SUMMARY = '/api/exams/summary',
	SUMMARY_BY_ID = '/api/exams/summary/',
	SUMMARY_SUBJECTS = '/api/exams/summary/headerInfo'
}

enum ANNUAL_SUMMARY {
	ANNUAL_SUMMARY = '/api/exams/summaryAcademicYear',
	ANNUAL_SUMMARY_BY_ID = '/api/exams/summaryAcademicYear/',
	ANNUAL_SUMMARY_SUBJECTS = '/api/exams/summaryAcademicYear/headerInfo'
}

export default {
	EXAMS,
	MARKS,
	DISTRIBUTION,
	CONSULTATION,
	SUMMARY,
	ANNUAL_SUMMARY
};
