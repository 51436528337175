import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExaminers = async (data: any, examID: number) => {
	const response = await axiosClient.get(api.EXAMS.EXAMS.EXAMS + `/${examID}/lecturers`, {params: data});
	return response.data;
};

const createExaminer = async (data: any, examID: number) => {
	const response = await axiosClient.post(api.EXAMS.EXAMS.EXAMS + `/${examID}/lecturers`, data);
	return response.data;
};

const updateExaminer = async (data: any, id: number, examID: number) => {
	const response = await axiosClient.patch(
		api.EXAMS.EXAMS.EXAMS_BY_ID + `/${examID}/lecturers/` + id,
		data
	);
	return response.data;
};

const deleteExaminer = async (id: number[], examID: number) => {
	const response = await axiosClient.delete(api.EXAMS.EXAMS.EXAMS_BY_ID + `/${examID}/lecturers/` + id);
	return response.data;
};

const service = {
	getExaminers,
	createExaminer,
	updateExaminer,
	deleteExaminer
};

export default service;
