import AUTH from './auth.service';
import ASSIGNMENTS from './assignments.service';
import SETTINGS from './settings.service';
import LECTURERS from './lecturers.service';
import CANDIDATES from './candidates.service';
import FACULTIES from './faculties.service';
import CANIDATE_EXAMS from './candidate.exams.service';
import PAYMENTS from './payments.service';
import DISTRIBUTION from './distribution.service';
import MARKS from './marks.service';
import APPEALS from './appeals.service';
import USERS from './users.service';
import PRIVILEGES from './privileges.service';
import PERMISSIONS from './permissions.service';
import EXCEL from './excel.service';
import SUMMARY from './summary.service';
import STUDENTS from './students.service';
import STUDENTS_SURVEYS from './student.surveys.service';
import STUDENTS_DISCOUNTS from './student.discounts.service';
import STUDENTS_ATTENDANCE from './student.attendance.service';
import STUDENTS_CERTIFICATES from './student.certificates.service';
import STUDENTS_DIPLOMA_WORKS from './student.diplomaWorks.service';
import COMMANDS from './commands.service';
import CURRICULUM_FACULTIES from './curriculum.faculties.service';
import CURRICULUM_FLOWS from './curriculum.flows.service';
import CURRICULUM_GROUPS from './curriculum.groups.service';
import CURRICULUM_LESSONS from './curriculum.lessons.service';
import CURRICULUM_LESSONS_WEEKS from './curriculum.lessonsWeeks.service';
import CURRICULUM_SCHEDULE from './curriculum.schedule';
import NOTIFICATIONS from './notifications.service';
import EXAMS from './exams.services';
import EXAMS_MARKS from './exams.marks.service';
import EXAMS_DISTRIBUTION from './exams.distribution.service';
import EXAMS_EXAMINERS from './exams.examiners.service';
import EXAMS_CONSULTATION from './exams.consultation.service';
import EXAMS_SUMMARY from './exams.summary.service';
import EXAMS_ANNUAL_SUMMARY from './exams.annualSummary.service';
import REPORTS_CLASSROOMS from './reports.classrooms.service';
import ATTENDANCE from './Attendance';
import LOG from './log.service';
import ARCHIVE_MARKS from './archive.marks.service';
import ARCHIVE_COMMANDS from './archive.commands.service';
import CHAT from './chat.service';

const SERVICES = {
	AUTH,
	ASSIGNMENTS,
	SETTINGS,
	LECTURERS,
	CANDIDATES,
	FACULTIES,
	CANIDATE_EXAMS,
	PAYMENTS,
	DISTRIBUTION,
	MARKS,
	APPEALS,
	USERS,
	PRIVILEGES,
	PERMISSIONS,
	EXCEL,
	SUMMARY,
	STUDENTS,
	STUDENTS_SURVEYS,
	STUDENTS_DISCOUNTS,
	STUDENTS_ATTENDANCE,
	STUDENTS_CERTIFICATES,
	STUDENTS_DIPLOMA_WORKS,
	COMMANDS,
	CURRICULUM_FACULTIES,
	CURRICULUM_FLOWS,
	CURRICULUM_GROUPS,
	CURRICULUM_LESSONS,
	CURRICULUM_LESSONS_WEEKS,
	CURRICULUM_SCHEDULE,
	NOTIFICATIONS,
	ARCHIVE_MARKS,
	ARCHIVE_COMMANDS,
	EXAMS,
	EXAMS_MARKS,
	EXAMS_DISTRIBUTION,
	EXAMS_EXAMINERS,
	EXAMS_CONSULTATION,
	EXAMS_SUMMARY,
	EXAMS_ANNUAL_SUMMARY,
	ATTENDANCE,
	REPORTS_CLASSROOMS,
	LOG,
	CHAT
};

export default SERVICES;
