import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExamsMarks = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.MARKS.MARKS, {params: data});
	return response.data;
};

const getExamsMarksById = async (id: number) => {
	const response = await axiosClient.get(api.EXAMS.MARKS.MARKS_BY_ID + id);
	return response.data;
};

const updateMark = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.EXAMS.MARKS.MARKS_BY_ID + id, data);
	return response.data;
};

const updateMarkPOST = async (data: any, id: number) => {
	const response = await axiosClient.post(api.EXAMS.MARKS.MARKS_BY_ID + id, data);
	return response.data;
};

const updateMarkConfirmation = async (data: any, ids: number[]) => {
	const response = await axiosClient.patch(api.EXAMS.MARKS.MARKS_CONFIRMATION + ids, data);
	return response.data;
};

const service = {
	getExamsMarks,
	getExamsMarksById,
	updateMark,
	updateMarkPOST,
	updateMarkConfirmation
};

export default service;
