import axiosClient from 'utils/axiosClient';
import api from 'api';

const getSurveys = async (data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.SURVEYS, {params: data});
	return response.data;
};

const getSurveyById = async (id: number) => {
	const response = await axiosClient.get(api.STUDENTS.SURVEYS_BY_ID + id);
	return response.data;
};

const createSurvey = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.SURVEYS, data);
	return response.data;
};

const updateSurvey = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.SURVEYS_BY_ID + id, data);
	return response.data;
};

const cloneSurveyQuestions = async (data: any, id: number) => {
	const response = await axiosClient.post(api.STUDENTS.SURVEYS_BY_ID + id + '/questions/copy', data);
	return response.data;
};

const deleteSurvey = async (id: number) => {
	const response = await axiosClient.delete(api.STUDENTS.SURVEYS_BY_ID + id);
	return response.data;
};

const getSurveyQuestions = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.SURVEYS_BY_ID + id + '/questions', {params: data});
	return response.data;
};

const getSurveyQuestionByID = async (surveyID: number, questionID: number) => {
	const response = await axiosClient.get(
		api.STUDENTS.SURVEYS_BY_ID + surveyID + '/questions/' + questionID
	);
	return response.data;
};

const createSurveyQuestion = async (data: any, id: number) => {
	const response = await axiosClient.post(api.STUDENTS.SURVEYS_BY_ID + id + '/questions', data);
	return response.data;
};

const updateSurveyQuestion = async (data: any, surveyID: number, id: number) => {
	const response = await axiosClient.patch(
		api.STUDENTS.SURVEYS_BY_ID + surveyID + '/questions/' + id,
		data
	);
	return response.data;
};

const deleteSurveyQuestion = async (questionID: number, ids: number[]) => {
	const response = await axiosClient.delete(api.STUDENTS.SURVEYS_BY_ID + questionID + '/questions/' + ids);
	return response.data;
};

const service = {
	getSurveys,
	getSurveyById,
	createSurvey,
	updateSurvey,
	cloneSurveyQuestions,
	deleteSurvey,
	getSurveyQuestions,
	getSurveyQuestionByID,
	createSurveyQuestion,
	updateSurveyQuestion,
	deleteSurveyQuestion
};

export default service;
