import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getCandidates = async (data: any) => {
	const response = await axiosClient.get(api.ADMISSION.CANDIDATES.CANDIDATE, {params: data});
	return response.data;
};

const getCandidateById = async (id: number) => {
	const response = await axiosClient.get(api.ADMISSION.CANDIDATES.CANDIDATE_BY_ID + id);
	return response.data;
};

const checkCandidateEditById = async (id: number) => {
	const response = await axiosClient.get(api.ADMISSION.CANDIDATES.CHECK_CANDIDATE_EDIT + id);
	return response.data;
};

const createCandidate = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.CANDIDATES.CANDIDATE, formData(data, 'ADD'), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const updateCandidate = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ADMISSION.CANDIDATES.CANDIDATE + '/' + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const deleteCandidates = async (id: number) => {
	const response = await axiosClient.delete(api.ADMISSION.CANDIDATES.CANDIDATE_BY_ID + id);
	return response.data;
};

const service = {
	getCandidates,
	getCandidateById,
	checkCandidateEditById,
	createCandidate,
	updateCandidate,
	deleteCandidates
};

export default service;
