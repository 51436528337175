import {io} from 'socket.io-client';

export const initializeSocket = (token: string) => {
	return io(process.env.REACT_APP_API_URL || '', {
		autoConnect: true,
		query: {
			token,
			memberType: 1
		}
	});
};
