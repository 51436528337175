import axiosClient from 'utils/axiosClient';
import api from 'api';

const getUserConnections = async (data: any) => {
	const response = await axiosClient.get(api.CHAT.GET_USER_CONNECTIONS, {params: data});
	return response.data;
};

const searchConnections = async (data: any) => {
	const response = await axiosClient.get(api.CHAT.SEARCH_CONNECTIONS, {params: data});
	return response.data;
};

const getRoomMembers = async (data: any) => {
	const response = await axiosClient.get(`${api.CHAT.GET_ROOM_MEMBERS}/${data}`);
	return response.data;
};
const service = {
	getUserConnections,
	searchConnections,
	getRoomMembers
};

export default service;
