import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getMarks = async (data: any) => {
	const response = await axiosClient.get(api.ADMISSION.MARKS.MARKS, {params: data});
	return response.data;
};

const updateMarks = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ADMISSION.MARKS.MARKS_BY_ID + id, data);
	return response.data;
};

const uploadMarks = async (data: any, examID: number) => {
	const response = await axiosClient.post(
		api.ADMISSION.MARKS.UPLOAD_MARKS + `${examID}/upload`,
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const service = {
	getMarks,
	updateMarks,
	uploadMarks
};

export default service;
