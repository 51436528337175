import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getDiplomaWorks = async (data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.DIPLOMA_WORKS, {params: data});
	return response.data;
};

const updateDimplomaWork = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.DIPLOMA_WORKS_BY_ID + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const distributeDimplomaWork = async (data: any) => {
	const response = await axiosClient.patch(api.STUDENTS.DISTRIBUTE_DIPLOMA_WORKS, data);
	return response.data;
};

const service = {
	getDiplomaWorks,
	updateDimplomaWork,
	distributeDimplomaWork
};

export default service;
