import axiosClient from 'utils/axiosClient';
import api from 'api';

const getFaculties = async (data?: any) => {
	const response = await axiosClient.get(api.ADMISSION.FACULTIES.FACULTIES, {params: data});
	return response.data;
};

const getFacultyById = async (id: number) => {
	const response = await axiosClient.get(api.ADMISSION.FACULTIES.FACULTIES_BY_ID + id);
	return response.data;
};

const createFaculty = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.FACULTIES.FACULTIES, data, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return response.data;
};

const updateFaculty = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ADMISSION.FACULTIES.FACULTIES_BY_ID + id, data, {
		headers: {
			'Content-Type': 'application/json'
		}
	});
	return response.data;
};

const deleteFaculty = async (id: number) => {
	const response = await axiosClient.delete(api.ADMISSION.FACULTIES.FACULTIES_BY_ID + id);
	return response.data;
};

const service = {
	updateFaculty,
	getFaculties,
	getFacultyById,
	createFaculty,
	deleteFaculty
};

export default service;
