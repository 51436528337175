import axiosClient from 'utils/axiosClient';
import api from 'api';

const getFaculties = async (data?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.FACULTIES.FACULTIES, {params: data});
	return response.data;
};

const getFacultyProfession = async (id?: number) => {
	const response = await axiosClient.get(api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + id + '/profession');
	return response.data;
};

const getFacultyById = async (id: number) => {
	const response = await axiosClient.get(api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + id);
	return response.data;
};

const createFaculty = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.FACULTIES.FACULTIES, data);
	return response.data;
};

const createFacultyProfession = async (data: any, id: number) => {
	const response = await axiosClient.post(
		api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + id + '/profession',
		data
	);
	return response.data;
};

const updateFaculty = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + id, data);
	return response.data;
};

const updateFacultyProfession = async (data: any, facultyID: number, id: number) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + facultyID + '/profession/' + id,
		data
	);
	return response.data;
};

const deleteFaculty = async (id: number) => {
	const response = await axiosClient.delete(api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + id);
	return response.data;
};

const deleteFacultyProfession = async (facultyID: number, id: number) => {
	const response = await axiosClient.delete(
		api.CURRICULUM.FACULTIES.FACULTIES_BY_ID + facultyID + '/profession/' + id
	);
	return response.data;
};

const service = {
	updateFaculty,
	getFaculties,
	getFacultyById,
	createFaculty,
	createFacultyProfession,
	deleteFaculty,
	deleteFacultyProfession,
	getFacultyProfession,
	updateFacultyProfession
};

export default service;
