import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getStudents = async (data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS, {params: data});
	return response.data;
};

const getStudentByID = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id, {params: data});
	return response.data;
};

const getStudentCommands = async (id: number, data?: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/commands', {params: data});
	return response.data;
};

const getStudentPayments = async (params: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENT_PAYMENTS, {params});
	return response.data;
};

const getStudentDocuments = async (params: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENT_DOCUMENTS, {params});
	return response.data;
};

const getStudentApplications = async (params: any) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENT_APPLICATIONS, {params});
	return response.data;
};

const getStudentGpa = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/gpa', {params});
	return response.data;
};

const getStudentEducationalProgram = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/lessonModel', {params});
	return response.data;
};

const getStudentExams = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/exams', {params});
	return response.data;
};

const getStudentPastEducation = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/pastEducation', {params});
	return response.data;
};

const getStudentEducationalHistory = async (studentID: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + studentID + '/educationHistory');
	return response.data;
};

const getStudentComputerSkills = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/computerProgram', {params});
	return response.data;
};
const getStudentLanguageSkills = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/language', {params});
	return response.data;
};
const getStudentMedical = async (params: any, id: number) => {
	const response = await axiosClient.get(api.STUDENTS.STUDENTS_BY_ID + id + '/medicalExamination', {
		params
	});
	return response.data;
};

const getAllDocuments = async (params: any) => {
	const response = await axiosClient.get(api.STUDENTS.DOCUMENTS, {params});
	return response.data;
};

const addStudentPersonal = async (data?: any) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENTS_BY_ID, {
		...data,
		residenceIsDifferentFromRegistration: !!data.residenceIsDifferentFromRegistration
	});
	return response.data;
};

const uploadStudentsATC_Data = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENTS_ATC, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const uploadStudentsArchive = async (data: any) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENTS_ARCHIVE, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const addStudentPayments = async (data: any, studentID?: number) => {
	const response = await axiosClient.post(
		api.STUDENTS.STUDENT_PAYMENTS,
		formData(studentID ? {...data, studentID} : data)
	);
	return response.data;
};

const addStudentDocuments = async (data: any, studentID: number) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENT_DOCUMENTS, formData({...data, studentID}));
	return response.data;
};

const addStudentApplication = async (data: any, studentID: number) => {
	const response = await axiosClient.post(
		api.STUDENTS.STUDENT_APPLICATIONS,
		formData({...data, studentID})
	);
	return response.data;
};

const addStudentPastEducation = async (data: any, studentID: number) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENTS_BY_ID + studentID + '/pastEducation', data);
	return response.data;
};

const addStudentComputerSkills = async (data: any, studentID: number) => {
	const response = await axiosClient.post(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/computerProgram',
		data
	);
	return response.data;
};

const addStudentLanguageSkills = async (data: any, studentID: number) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENTS_BY_ID + studentID + '/language', data);
	return response.data;
};

const addStudentMedical = async (data: any, studentID: number) => {
	const response = await axiosClient.post(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/medicalExamination',
		data
	);
	return response.data;
};

const updateStudentPersonal = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.STUDENTS_BY_ID + id, data);
	return response.data;
};

const updateStudentAdmission = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.STUDENTS_BY_ID + id + '/admission', data);
	return response.data;
};

const updateStudentParential = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.STUDENTS_BY_ID + id + '/parential', data);
	return response.data;
};

const updateStudentEducation = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.STUDENTS_BY_ID + id + '/education', data);
	return response.data;
};

const updateStudentEducationBasicInfo = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.STUDENTS.STUDENTS_BY_ID + id + '/educationBasicInfo', data);
	return response.data;
};

const updateStudentPastEducation = async (data: any, pastEducationID: number, studentID: number) => {
	const response = await axiosClient.patch(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/pastEducation/' + pastEducationID,
		data
	);
	return response.data;
};

const updateStudentPayment = async (data: any, paymentID: number) => {
	const response = await axiosClient.patch(
		api.STUDENTS.STUDENT_PAYMENTS_BY_ID + paymentID,
		formData({
			...data
		})
	);
	return response.data;
};

const updateStudentDocuments = async (data: any, documentID: number) => {
	const response = await axiosClient.patch(
		api.STUDENTS.STUDENT_DOCUMENTS_BY_ID + documentID,
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const updateStudentApplication = async (data: any, applicationID: number) => {
	const response = await axiosClient.patch(
		api.STUDENTS.STUDENT_APPLICATIONS_BY_ID + applicationID,
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const chooseOptionalSubject = async (studentID: number, subjectIDs: number[]) => {
	const response = await axiosClient.patch(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/lessonModel/' + subjectIDs
	);
	return response.data;
};

const deleteStudent = async (id: number) => {
	const response = await axiosClient.delete(api.STUDENTS.STUDENTS_BY_ID + id);
	return response.data;
};

const deleteStudentPayment = async (paymentID: number) => {
	const response = await axiosClient.delete(api.STUDENTS.STUDENT_PAYMENTS_BY_ID + paymentID);
	return response.data;
};

const deleteStudentDocuments = async (documentID: number) => {
	const response = await axiosClient.delete(api.STUDENTS.STUDENT_DOCUMENTS_BY_ID + documentID);
	return response.data;
};

const deleteStudentApplication = async (applicationID: number) => {
	const response = await axiosClient.delete(api.STUDENTS.STUDENT_APPLICATIONS_BY_ID + applicationID);
	return response.data;
};

const deleteStudentPastEducation = async (pastEducationID: number, studentID: number) => {
	const response = await axiosClient.delete(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/pastEducation/' + pastEducationID
	);
	return response.data;
};
const deleteStudentCumputerSkill = async (computerProgramID: number, studentID: number) => {
	const response = await axiosClient.delete(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/computerProgram/' + computerProgramID
	);
	return response.data;
};
const deleteStudentLanguageSkill = async (languageID: number, studentID: number) => {
	const response = await axiosClient.delete(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/language/' + languageID
	);
	return response.data;
};
const deleteStudentMedical = async (languageID: number, studentID: number) => {
	const response = await axiosClient.delete(
		api.STUDENTS.STUDENTS_BY_ID + studentID + '/medicalExamination/' + languageID
	);
	return response.data;
};

const sendPasswordResetMails = async (IDs: number[], filters: any) => {
	const response = await axiosClient.post(api.STUDENTS.STUDENTS_SEND_PASSWORD_RESET_MAILS, {
		IDs,
		filters
	});
	return response.data;
};

const service = {
	getStudents,
	getStudentByID,
	getStudentPayments,
	uploadStudentsATC_Data,
	uploadStudentsArchive,
	getStudentEducationalProgram,
	getStudentDocuments,
	getStudentApplications,
	getStudentGpa,
	getStudentPastEducation,
	getStudentExams,
	getStudentEducationalHistory,
	getStudentComputerSkills,
	getStudentLanguageSkills,
	getStudentMedical,
	getAllDocuments,
	addStudentPersonal,
	addStudentPayments,
	addStudentLanguageSkills,
	addStudentComputerSkills,
	addStudentMedical,
	addStudentDocuments,
	addStudentApplication,
	addStudentPastEducation,
	updateStudentPersonal,
	updateStudentAdmission,
	updateStudentParential,
	updateStudentEducation,
	updateStudentEducationBasicInfo,
	updateStudentPayment,
	updateStudentDocuments,
	updateStudentApplication,
	updateStudentPastEducation,
	deleteStudent,
	deleteStudentPayment,
	deleteStudentDocuments,
	deleteStudentLanguageSkill,
	deleteStudentCumputerSkill,
	deleteStudentMedical,
	deleteStudentApplication,
	deleteStudentPastEducation,
	sendPasswordResetMails,
	chooseOptionalSubject,
	getStudentCommands
};

export default service;
