import ABSENCE from './absence.services';
import PRESENCE from './presence.services';
import MAIN from './main.services';
import COMPLETED_CLASSES from './completedClasses.services';

const SERVICES = {
	ABSENCE,
	PRESENCE,
	MAIN,
	COMPLETED_CLASSES
};

export default SERVICES;
