import axiosClient from 'utils/axiosClient';
import api from 'api';

const getLogs = async (data: any) => {
	const response = await axiosClient.get(api.LOG.LOG, {params: data});
	return response.data;
};

const service = {
	getLogs
};

export default service;
