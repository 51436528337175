import {Layout} from 'antd';
import {useTranslation} from 'react-i18next';
import packageJSON from '../../../../package.json';
import {useContext} from 'react';
import {SettingsContext} from 'context/Settings.context';
const {Footer} = Layout;

const AppFooter = () => {
	const {t} = useTranslation();
	const {
		state: {appConfigs}
	} = useContext(SettingsContext);

	return (
		<Footer
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				padding: '8px 40px',
				textAlign: 'center',
				background: '#fff'
			}}>
			<div>v{packageJSON.version}</div>
			<div>
				© {new Date().getFullYear()}, {appConfigs.footerText || t('footer_university')}
			</div>
		</Footer>
	);
};

export default AppFooter;
