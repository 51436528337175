import {Modal, notification} from 'antd';
import {CheckCircle, XCircle, WarningCircle} from 'phosphor-react';
import './index.less';

const {error} = Modal;

let shown = false;

notification.config({
	maxCount: 1
});

function getIcon(type: 'success' | 'error' | 'warning') {
	switch (type) {
		case 'success':
			return <CheckCircle color="#6C5DD3" size={30} weight="fill" />;
		case 'warning':
			return <WarningCircle color="#e39024" size={30} weight="fill" />;
		case 'error':
			return <XCircle color="#CE1126" size={30} weight="fill" />;
		default:
			return <CheckCircle color="#CE1126" size={30} weight="fill" />;
	}
}

const toast = ({text, type}: {text: string; type: 'success' | 'error' | 'warning'}) => {
	notification.info({
		message: text,
		// description: text,
		placement: 'top' as any,
		icon: getIcon(type)
	});
};

const errorModal = ({
	text,
	onConfirm,
	params,
	maskClosable = true,
	okText,
	showCancelBTN
}: {
	text: string;
	onConfirm?: (data?: any) => void;
	okText?: string | undefined;
	params?: Record<string, any>;
	maskClosable: boolean | undefined;
	showCancelBTN: boolean;
}) => {
	error({
		title: text,
		className: 'modalPre',
		okButtonProps: {size: 'large', className: 'main-btn'},
		cancelButtonProps: {size: 'large', className: 'main-btn cancelBTN'},
		onCancel: () => (shown = false),
		onOk: () => (onConfirm ? onConfirm(params) : (shown = false)),
		okText,
		centered: true,
		okType: 'ghost',
		okCancel: showCancelBTN,
		closable: false,
		maskClosable,
		width: 600
	});
};

export default {
	success: (text: string) => toast({type: 'success', text}),
	warning: (text: string) => toast({type: 'warning', text}),
	error: (
		text: string,
		props?: {
			onConfirm?: (data: any) => void;
			params?: Record<string, any>;
			maskClosable?: boolean;
			okText?: string | undefined;
			showCancelBTN?: boolean;
		}
	) => {
		errorModal({
			text,
			onConfirm: props?.onConfirm,
			okText: props?.okText,
			showCancelBTN: props?.showCancelBTN || false,
			params: props?.params,
			maskClosable: props?.maskClosable
		});
		//eslint-disable-next-line
		shown = true;
	}
};
