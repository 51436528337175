const deleteStorageDefaultKeys = () => {
	const DEFAULT_STORAGE_DELETE_KEYS = [
		'privilegeId',
		'settingsPage',
		'lastName',
		'token',
		'email',
		'sider_collapsed',
		'id',
		'name',
		'prevLang',
		'userTypeID',
		'watchKeys',
		'selectedKeys',
		'settingsSubPage',
		'sider_collapsed',
		'settingsOpenKeys'
	];

	DEFAULT_STORAGE_DELETE_KEYS.forEach((el: string) => {
		localStorage.removeItem(el);
	});
};

export default {
	deleteStorageDefaultKeys
};
