import axiosClient from 'utils/axiosClient';
import api from 'api';

const getExamsAnnualSummary = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.ANNUAL_SUMMARY.ANNUAL_SUMMARY, {params: data});
	return response.data;
};

const getAnnualSummarySubjects = async (data: any) => {
	const response = await axiosClient.get(api.EXAMS.ANNUAL_SUMMARY.ANNUAL_SUMMARY_SUBJECTS, {
		params: data
	});
	return response.data;
};

const createExamAnnualSummary = async (data: any) => {
	const response = await axiosClient.post(api.EXAMS.ANNUAL_SUMMARY.ANNUAL_SUMMARY, data);
	return response.data;
};

const updateExamAnnualSummary = async (data: any, ids: number[]) => {
	const response = await axiosClient.patch(api.EXAMS.ANNUAL_SUMMARY.ANNUAL_SUMMARY_BY_ID + ids, data);
	return response.data;
};

const service = {
	getExamsAnnualSummary,
	getAnnualSummarySubjects,
	createExamAnnualSummary,
	updateExamAnnualSummary
};

export default service;
