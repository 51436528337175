import axiosClient from 'utils/axiosClient';
import api from 'api';

const getAppeals = async (data: any) => {
	const response = await axiosClient.get(api.ADMISSION.APPEALS.APPEALS, {params: data});
	return response.data;
};

const createAppeal = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.APPEALS.APPEALS, data);
	return response.data;
};

const updateAppeals = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ADMISSION.APPEALS.APPEALS_BY_ID + id, data);
	return response.data;
};

const deleteAppeal = async (id: number) => {
	const response = await axiosClient.delete(api.ADMISSION.APPEALS.APPEALS_BY_ID + id);
	return response.data;
};

const distribute = async (data: any) => {
	const response = await axiosClient.post(api.ADMISSION.APPEALS.DISTRIBUTION, data);
	return response.data;
};

const getDistributions = async (params: any) => {
	const response = await axiosClient.get(api.ADMISSION.APPEALS.DISTRIBUTION, {params});
	return response.data;
};

const deleteDistribution = async (id: number) => {
	const response = await axiosClient.delete(api.ADMISSION.APPEALS.DISTRIBUTION + '/' + id);
	return response.data;
};

const service = {
	getAppeals,
	createAppeal,
	updateAppeals,
	deleteAppeal,
	distribute,
	getDistributions,
	deleteDistribution
};

export default service;
