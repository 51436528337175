import axiosClient from 'utils/axiosClient';
import api from 'api';
import {formData} from 'utils/helpers/formData';

const getPresence = async (params?: any) => {
	const response = await axiosClient.get(api.ATTENDANCE.PRESENCE.PRESENCE, {params});
	return response.data;
};

const getPresenceById = async (id: number, params?: any) => {
	const response = await axiosClient.get(api.ATTENDANCE.PRESENCE.PRESENCE_BY_ID + id, {params});
	return response.data;
};

const updatePresence = async (data: any, id: number) => {
	const response = await axiosClient.patch(api.ATTENDANCE.PRESENCE.PRESENCE_BY_ID + id, formData(data), {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	});
	return response.data;
};

const getRespectableAbsents = async (studentID: number, params?: any) => {
	const response = await axiosClient.get(
		api.ATTENDANCE.PRESENCE.PRESENCE_BY_ID + '/' + studentID + '/respectableAbsents',
		{
			params
		}
	);
	return response.data;
};

const createRespectableAbsent = async (studentID: number, data: any) => {
	const response = await axiosClient.post(
		api.ATTENDANCE.PRESENCE.PRESENCE_BY_ID + '/' + studentID + '/respectableAbsents',
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const updateRespectableAbsent = async (studentID: number, data: any, id: number) => {
	const response = await axiosClient.patch(
		api.ATTENDANCE.PRESENCE.PRESENCE_BY_ID + '/' + studentID + '/respectableAbsents' + '/' + id,
		formData(data),
		{
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		}
	);
	return response.data;
};

const deleteRespectableAbsents = async (studentID: number, ids: number[]) => {
	const response = await axiosClient.delete(
		api.ATTENDANCE.PRESENCE.PRESENCE_BY_ID + '/' + studentID + '/respectableAbsents' + '/' + ids
	);
	return response.data;
};

const service = {
	getPresence,
	getPresenceById,
	updatePresence,
	getRespectableAbsents,
	createRespectableAbsent,
	updateRespectableAbsent,
	deleteRespectableAbsents
};

export default service;
