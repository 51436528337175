import AUTH from './auth.api';
import ASSIGNMENTS from './assignments.api';
import SETTINGS from './settings.api';
import LECTURERS from './lecturers.api';
import USERS from './users.api';
import PRIVILEGES from './privileges.api';
import PERMISSIONS from './permissions.api';
import EXCEL from './excel.api';
import STUDENTS from './students.api';
import COMMANDS from './commands.api';
import ADMISSION from './admission.api';
import CURRICULUM from './curriculum.api';
import NOTIFICATIONS from './notifications.api';
import EXAMS from './exams.api';
import ATTENDANCE from './attendance.api';
import ARCHIVE from './archive.api';
import REPORTS from './reports.api';
import LOG from './log.api';
import CHAT from './chat.api';

const APP_API = {
	AUTH,
	ASSIGNMENTS,
	SETTINGS,
	LECTURERS,
	USERS,
	PRIVILEGES,
	PERMISSIONS,
	EXCEL,
	STUDENTS,
	COMMANDS,
	ADMISSION,
	CURRICULUM,
	NOTIFICATIONS,
	EXAMS,
	ARCHIVE,
	REPORTS,
	ATTENDANCE,
	LOG,
	CHAT
};

export default APP_API;
