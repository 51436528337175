import axiosClient from 'utils/axiosClient';
import api from 'api';

const getLessonsWeeks = async (params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.LESSONS_LIST.LESSONS_WEEKS, {
		params
	});
	return response.data;
};

const getLessonsWeekByID = async (id: number | string, params?: any) => {
	const response = await axiosClient.get(api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + id, {params});
	return response.data;
};

const getLessonsWeekLessons = async (id: number | string, params?: any) => {
	const response = await axiosClient.get(
		api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + id + '/main/lessons',
		{
			params
		}
	);
	return response.data;
};

const getLessonByID = async (lessonWeekID: number | string, id: any) => {
	const response = await axiosClient.get(
		api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + `${lessonWeekID}/${id}`
	);
	return response.data;
};

const createLessonsWeek = async (data: any) => {
	const response = await axiosClient.post(api.CURRICULUM.LESSONS_LIST.LESSONS_WEEKS, data);
	return response.data;
};

const updateLessonsWeek = async (data: any, modelID: number) => {
	const response = await axiosClient.patch(api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + modelID, data);
	return response.data;
};

const deleteLessonsWeek = async (ids: number) => {
	const response = await axiosClient.delete(api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + ids);
	return response.data;
};

const addLessonToList = async (lessonWeekID: number | string, data: any) => {
	const response = await axiosClient.post(
		api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + lessonWeekID,
		data
	);
	return response.data;
};

const updateLesson = async (lessonWeekID: number, lessonsID: number, data: any) => {
	const response = await axiosClient.patch(
		api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + lessonWeekID + '/' + lessonsID,
		data
	);
	return response.data;
};

const deleteLessonFromWeek = async (lessonWeekID: number, lessonsIDs: number | number[]) => {
	const response = await axiosClient.delete(
		api.CURRICULUM.LESSONS_LIST.LESSONS_WEEK_BY_ID + lessonWeekID + '/' + lessonsIDs
	);
	return response.data;
};

const service = {
	getLessonsWeeks,
	getLessonsWeekByID,
	getLessonsWeekLessons,
	getLessonByID,
	createLessonsWeek,
	updateLessonsWeek,
	deleteLessonsWeek,
	addLessonToList,
	updateLesson,
	deleteLessonFromWeek
};

export default service;
